@import "../../../styles/mixins.scss";

$card-width: 201px;
$card-height: 113px;

.section-layout {
  height: 100%;
  position: relative;
  overflow: auto;
  .card-group {
    grid-template-columns: repeat(auto-fill, minmax(201px, 1fr));
      @media (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 800px) {
    .image-layer, .image-layer-container, .background-layer {
      min-width: 150px !important;
    }
  }

  .card-title {
    font-size: 1rem !important;
    margin-bottom: 0.2rem !important;
  }

  div.card-group {
    flex-wrap: wrap;
    gap: 7px;
    display: grid;
  }

  div.card {
    cursor: pointer;
    border-radius: 0px;
    aspect-ratio: calc(16 / 9);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px;
    width: 100%;
    height: 100%;
    min-height: $card-height;
    min-width: $card-width;
  }

  .card-img {
    max-width: 70px;
    max-height: 70px;

    @media (max-width: 1200px) {
      max-width: 55px;
      max-height: 55px;
    }

    @media (min-width: 1201px) {
      max-width: 70px;
      max-height: 70px;
    }

    @media (max-width: 950px) {
      max-width: 40px;
      max-height: 40px;
    }
  }

  .section-card-body{
    padding: 0.5rem !important;
  }

  .image-layer {
    object-fit: contain;
    min-height: $card-height;
    min-width: $card-width;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
  }

  .image-layer-container {
    display: flex;
    justify-content: center;
    margin-left: -20px;
    margin-top: -20px;
    min-height: $card-height;
    min-width: $card-width;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
  }

  @supports (backdrop-filter: none) {
    .backdrop-blur {
      backdrop-filter: blur(5px);
    }
  }

  .background-layer {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    min-height: $card-height;
    min-width: $card-width;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    margin-bottom: 0px;
    border-radius: 10px;
  }
}