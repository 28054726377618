$chat-bubble-width: 50%;

.preview-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    a {
        color: black !important;
    }
}

.thread-title-preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thread-list {
    overflow-y: auto;
    height: calc(87vh - 112px);
    direction: ltr;
    margin: 10px 0px;
    padding: 0 5px;
}

.thread-list::-webkit-scrollbar {
    width: 5px !important;
    background-color: white !important;
}
.thread-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px white !important;
}

.thread-list::-webkit-scrollbar-thumb {
    background-color: #8c8c8c !important;
    border-radius: 2px !important;
}

.thread {
    padding-right: 10px;
    display: flex;
    border-radius: 0px;
    height: 75px;
    direction: ltr;
    cursor: pointer;
}

.thread-list-container {
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 87vh;
    overflow: hidden;
}

.group-setting-container {
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 87vh;
    overflow-y: scroll;
}

.group-setting-container::-webkit-scrollbar {
    width: 0px;
}

.thread:hover {
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
}

.thread-list-title {
    font-size: 22px !important;
    line-height: 35px !important;
    font-family: 'Gilroy';
    font-weight: 600 !important;
}

.chat-box {
    padding: 10px;
    height: 87vh;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.chat-bubble {
    border-radius: 25px;
    font-size: 14px;
    font-family: 'Gilroy';
    font-weight: 400;
    width: fit-content;
    max-width: $chat-bubble-width;
    padding: 10px 15px;

    p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

}

.bubble-container {
    display: flex;
}

.avatar {
    margin: 5px;
}

.avatar-hide {
    margin: 5px;
}

.bubble-container {
    a {
        word-wrap: break-word;
    }
}

.chat-bubble-author-name {
    font-size: 14px;
    font-weight: 600;
}

.pb5 {
    padding-bottom: 5px !important;
}

.bubble-container.primary {
    justify-content: flex-end;
}

.bubble-container.secondary {
    justify-content: flex-start;
}

.chat-bubble.primary.start {
    border-bottom-right-radius: 10px !important;
    margin-bottom: 3px !important;
}

.chat-bubble.secondary.start {
    border-bottom-left-radius: 10px !important;
    margin-bottom: 3px !important;
    margin-top: 1rem;
}

.chat-bubble.secondary.middle {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin-bottom: 2px !important;
}

.chat-bubble.primary.middle {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    margin-bottom: 2px !important;
}

.chat-bubble.primary.end {
    border-top-right-radius: 10px !important;
    margin-bottom: 3px !important;
}

.chat-bubble.secondary.end {
    border-top-left-radius: 10px !important;
    margin-bottom: 3px !important;
}

.chat-bubble.secondary.single {
    margin-bottom: 2px !important;
    margin-top: 1rem;
    min-width: 3em;
}

.chat-bubble.primary.single {
    margin-bottom: 2px !important;
    min-width: 3em;
}

.chat-box-header {
    display: flex;
    align-items: center;
    height: 60px;
}

.chat-box-threads {
    overflow-y: auto;
    padding-right: 17px;
    overflow-x: hidden;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
}

.chat-box-threads::-webkit-scrollbar {
    width: 5px !important;
    background-color: white !important;
}
.chat-box-threads::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px white !important;
}

.chat-box-threads::-webkit-scrollbar-thumb {
    background-color: #8c8c8c !important;
    border-radius: 2px !important;
}

.thread-info {
    width: 340px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px;
}

.thread-info-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.thread-info-avatar {
    margin: 20px 0;
    text-align: center;
}

.chat-box-more-info-btn {
    border: 0px;
    background: none;
}

.chat-box-more-info-btn:hover {
    background-color: #eee;
    border-radius: 100%;
}

.chat-box-more-info-btn-selected {
    border: 0px;
    background-color: #eee;
    border-radius: 100%;
}

.more-info-ellipsis {
    font-size: 30px;
    line-height: 1.2;
    color: black;
}

.thread-list-contacts {
    overflow-y: scroll;
    direction: ltr;
    margin-top: 5px;
    padding: 0 5px;
}

.selected-user-section {
    width: 100%;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.chat-bubble-img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
    display: block;
}

.chat-bubble-img-placeholder-text {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-size: 20px;
    font-family: 'Gilroy';
    font-weight: 700;
}

.chat-image-modal {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    overflow: hidden;
    background-color: rgba(17, 27, 33, 0.95);
    border: 0;
}

.mute-section {
    color: black !important;

    .mute-switch-section {
        .MuiSwitch-root {
            width: 55px !important;
        }
    }
}

.mute-popover-menu {
    .MuiPopover-paper {
        border-radius: 8px !important;
    }
}

.radio-option {
    height: 30px;

    .MuiFormControlLabel-label {
        font-weight: 400;
        color: black !important;
        font-size: 0.765625rem;
    }
}

.mute-title {
    font-weight: 700;
    line-height: 1.5;
    font-size: 0.765625rem;
    color: black;
}

.message-editor-section {
    width: calc(100% - 80px);

    .message-input {
        margin: 0px;
        border-radius: 20px;
        max-height: 250px !important;
        background: #f0f0f0 !important;
        border: none;
        box-shadow: none;
        overflow-y: auto !important;
        line-height: 1.4375em;
        color: rgba(0, 0, 0, 0.87);
        padding: 10px 14px !important;
    }

    .message-input::-webkit-scrollbar {
        width: 0px;
    }

}

.participant-user-avatar {
    height: 21px !important;
    width: 21px !important;
    border-radius: 100% !important;
}

.participant-title {
    Font: "Gilroy-SemiBold";
    font-Size: 12px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.participant-users-section {
    background: #EEEEEE;
    border: transparent;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 10px;
    padding: 5px 10px !important;
}

.selected-participant-section {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-height: 70px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.selected-participant-section::-webkit-scrollbar {
    width: 0px;
}

.group-image-upload-section {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-image-upload {
    height: 208px;
    width: 208px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-image-placeholder {
    font-family: "Gilroy";
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 17px;
}

.user-group-name {
    border-radius: 10px;
    outline: none;
    border: transparent;
    background: #EEEEEE;
    height: 45px;
    padding-right: 10px;
    padding-left: 10px;
}

.user-group-description {
    border-radius: 10px;
    outline: none;
    border: transparent;
    resize: none;
    background: #EEEEEE;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 18px;
}

.user-group-description::-webkit-scrollbar {
    width: 0px;
}

.next-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 113px;
    position: sticky;
    bottom: 0;
    background: white;
}

.next-btn {
    border-radius: 100%;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: transparent;
}

.next-btn-icon {
    color: white;
    width: 30px;
    height: 26px;
}

.group-profile-image {
    border-radius: 100%;
}

.edit-group-icon {
    margin-right: 5px;
    width: 21px;
    height: 21px;
}

.vertical-line {
    &::before {
        content: '';
        display: block;
        width: 5px;
        height: 80px;
        position: absolute;
        left: 15px;
        border-radius: 10px;
    }
}

.leave-group-btn {
    width: 100%;
    border-radius: 10px;
    outline: none;
    height: 37px;
    border: transparent;
}

.leave-group-icon {
    height: 20px;
    width: 20px;
}

.leave-group-text {
    Font: "Gilroy-SemiBold";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

.manage-participant-button {
    outline: none;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.manage-participant-checkbox {
    font-size: 24px;
}

.menu-btn {
    outline: none;
    background: transparent;
    border: none;
    padding-right: 5px;
}

.menu-icon {
    height: 17px;
    width: 17px;
}

.archive-btn, .mute-btn, .archive-btn:focus, .mute-btn:focus {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    padding: 10px;
    box-shadow: none;
}

.mute-option-text, .archive-option-text {
    font-size: 14px;
    Font: "Gilroy";
}

.mute-option-icon, .archive-option-icon {
    font-size: 18px;
    width: 20px;
}

.thread-options {
    .MuiPopover-paper{
        padding: 5px !important;
        border-radius: 8px !important;
    }
}

.deleted-message-icon {
    margin-right: 5px;
}

.deleted-message {
    filter: brightness(0.7) !important; 
}