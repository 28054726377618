$docHeight: 60px;
$imgHeight: 200px;

.files-display-comp {
  .media-container {
    .img-preview {
      max-height: $imgHeight;
    }

    .img-preview-single {
      max-height: 300px;
      width: 100%;
      object-fit: scale-down;
    }

    .preview-single-video {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 16/9;
    }

    .preview-single-video:fullscreen {
      object-fit: contain;
    }

    .img-preview-col {

      &:hover {
        box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.2);
      }

      .video-preview-icon {
        margin-left: 0px !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.9;
        z-index: 1;
      }

      .files-extender {
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .docs-container {
    display: inline-block;
    width: 50%;
    padding-left: 12px;
    padding-top: 10px;
    &.scroll {
      height: $docHeight * 2.5;
    }

    .doc-preview {
      padding: 12px;
      border-radius: 10px;
      height: $docHeight;
      background: #e5e5e5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      img {
        float: left !important;
        margin-left: 0 !important;
        margin-right: 10px !important;
        max-height: 100%;
        object-fit: contain;
      }

      span {
        color: black;
      }
    }
  }
}

.files-list-modal {
  .files-container {
    height: 600px;

    .file-item {
      max-height: 150px;
      width: 100%;
      margin-bottom: 5px;
      padding: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .file-item-img-preview {
        object-fit: cover;
        border-radius: 10px;
        max-width: 50%;
        max-height: 100%;
      }

      .file-item-video-preview {
        object-fit: cover;
        border-radius: 10px;
        width: 50%;
        height: 140px;
      }
    }
  }
}

.file-preview-modal {
  .preview-container {
    height: 600px;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.only-media-element {
  margin: 0px -20px;

  img {
    max-width: 500px;
  }
}

@supports (backdrop-filter: none) {
  .backdrop-blur {
    backdrop-filter: blur(50px);
  }
}

$image-width: 300px;
$image-height: 168px;

.background-layer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  min-height: $image-height;
  min-width: $image-width;
  top: 0px;
  left: 0px;
  margin-bottom: 0px;
  border-radius: 4px;
}

@supports (backdrop-filter: none) {
  .backdrop-blur {
    backdrop-filter: blur(5px);
  }
}

.image-layer {
  object-fit: contain;
  min-height: $image-height;
  min-width: $image-width;
  width: auto;
  height: 100%;
  position: relative;
  border-radius: 4px;
}

.image-layer-container {
  display: flex;
  justify-content: center;
  min-height: $image-height;
  min-width: $image-width;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
}

.img-rad {
  border-radius: 4px;
}
