.send-p2p-btn {
    width: fit-content;
    color: white;
    border: none;
    border-radius: 10px;
    display: flex;
    padding: 1px;
}

.send-p2p-btn-text {
    margin-top: 0.5rem;
}

.tyc-icon-feed {
    font-size: 28px;
    text-align: center;
    width: 28px;
}

img.tyc-icon-feed {
    width: 40px;
}