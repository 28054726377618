.manage-participant-modal {

    .modal-header {
        border: none !important;
        padding: 10px 16px 5px 16px;
    }

    .modal-title {
        display: flex;
        width: 100%;
    }

    .manage-participant-title {
        color: black;
    }

    .search-box, .box {
        padding: 5px 16px;
    }

    .user-list-box {
        padding: 5px 16px;
        height: 480px;
        margin-bottom: 5px;
    }

    .form-check-input:focus {
        box-shadow: none !important;
    }

    .active-tab, .active-tab:hover {
        background: #15385B !important;
        color: white;
    }

    .participant-btn {
        Font: "Gilroy-SemiBold";
        font-size: 16px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .admins-btn {
        Font: "Gilroy-SemiBold";
        font-size: 16px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .btn-group button {
        box-shadow: 0px 3.0799994468688965px 3.0799994468688965px 0px #0000001A;
        background: #FFFFFF;
    }
}