@import "../../../styles/mixins.scss";

.infinite-scroll-component__outerdiv {
  height: 100%;
}

.custom-layout {
  height: 100%;

  .card-group {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .preview-image {
    max-width: 100%;
    object-fit: cover;
  }

  div.card-group {
    flex-wrap: wrap;
    gap: 20px;
    display: grid;
    .card {
      cursor: pointer;
    }
  }

  .card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transition: 0.3s;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .card-body {
    display: flex;
    flex-direction: column;
  }

  .card-text {
    overflow-y: auto;
  }

  .page-tile-title, .font-black {
    color: #212427;
  }

  .social-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
  }

  .social-bar-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .input-group-prepend {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
  }

  .input-group-text {
    background-color: #fff;
  }

  .input-search-icon {
    color: #0091b8;
  }

  .input-group {
    margin-top: 20px;
  }

  em.fa.fa-heart.like-full {
    color: #d9534f;
  }

  .minHeight10 {
    min-height: 10vh;
  }
  .minHeight70 {
    min-height: 70vh;
  }
  .height70{
    height: 70vh;
  }

  .height80{
    height: 80vh;
  }

  .maxHeight28 {
    max-height: 28vh;
  }

  .maxHeight100 {
    max-height: 100vh;
  }

  .height28 {
    height: 28vh;
  }

  .height58 {
    height: 58vh;
  }

  .section-column {
    min-height: 20vh;
    max-height: 58vh; 
    overflow-y: auto;

    .card-body {
      height: auto !important;
    }
  }

  .section-column::-webkit-scrollbar, .maxHeight28::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background-color: white !important;
  }

  .section-column::-webkit-scrollbar-track, .maxHeight28::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px white !important;
  }

  .section-column::-webkit-scrollbar-thumb, .maxHeight28::-webkit-scrollbar-thumb {
      background-color: #8c8c8c !important;
      border-radius: 2px !important;
  }
}
