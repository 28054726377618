.collapser {
    -webkit-transition: max-height 1s; 
    -moz-transition: max-height 1s; 
    -ms-transition: max-height 1s; 
    -o-transition: max-height 1s; 
    transition: max-height 1s;  

    overflow: hidden;

    &.hide {
        max-height: 100px;
    }

    &.show {
        max-height: 300px;
    }
}