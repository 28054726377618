@import '../../../../../styles/custom.scss';

.fake-modal-container {
  position: absolute;
  float: right;
  right: 15px;
  padding: 15px 15px 10px 15px;
  width: auto;
  top: 60px;
  background: #FFFFFF;
  box-shadow: 0px 3px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-post-item {
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.10));
  border: none;
  border-radius: 10px;
}

.like-btn {
  font-style: normal;
  color: #f03753;
  font-size: 1.3rem;
}

.comment-btn-primary {
  color:#008651;
}

.comment-btn {
  font-style: normal;
  font-size: 1.3rem;
}

.media-option {
  font-size: 1rem;
}

.actions {
  font-size: 12pt!important;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 600;
}

.action-icons {
  padding-right: 10px;
  &.report {
    padding-right: 15px;
  }
  &.snooze {
    padding-right: 18px;
  }
}

.report-label {
  font-size: 12pt;
  font-weight: 600;
  border-bottom: solid 1pt #B9B9B9;
}

.report-input {
  border-radius: 5px;
  border: none;
  color: #000000;
  background: #F1F1F1;
  margin-bottom: 20px;
  padding: 5px;
  resize: none;
}

.send-button, .send-button:hover {
  background-color: #0ABB9A;
  border-color: #0ABB9A;
  color: white;
  margin-bottom: 20px;
  font-weight: 700;
  width: 80px;
}
.cancel-button, .cancel-button:hover {
  background-color: #F54E60;
  border-color: #F54E60;
  color: white;
  margin-bottom: 20px;
  font-weight: 700;
  width: 80px;
}

.report-btn-container {
  padding: 0px 20px 0px 20px
}

@media (min-width: 992px) and (max-width: 1200px) {
  .font-primary-text {
      font-size: 1rem !important;
  }

  .font-secondary-text {
      font-size: 0.8rem !important;
  }
}

.social-post-user-details {
  display: flex;
}

.social-post-user-details-description {
  display: flex;
  justify-content: space-between;
}

.user-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1px;
  margin-top: 5px;
}