.following-detail-modal {
    .social-tab {
        font-size: 18px !important;
        font-family: "Gilroy", sans-serif !important;
        line-height: 23.44px;
        color: #000000 !important;
        opacity: 1 !important;
        font-weight: 600;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0;
        font-weight: 600;
        border-bottom: 5px solid transparent;
    }

    .user-name {
        font-size: 18px !important;
        font-family: "Gilroy", sans-serif !important;
        line-height: 23.44px;
        color: #000000 !important;
        opacity: 1 !important;
        font-weight: 600;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 0;
        font-weight: 600;
        cursor: pointer;
        max-width: 280px;
    }

    .following-detail-modal-header {
        padding: 0 16px;
    }

    .active-social-tab {
        border-bottom: 5px solid #15385B;
    }

    .user-profile-image {
        height: 49px;
        width: 49px;
        border-radius: 100%;
    }

    .followed-button {
        height: 26px;
        background: #ECECEC;
        font-family: "Gilroy", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        border-radius: 10px;
        border: 1px solid #ECECEC;
        color: #000000;
    }

    .follows-you {
        height: 26px;
        background: #ECECEC;
        font-family: "Gilroy", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        border-radius: 10px;
        border: 1px solid #ECECEC;
        color: #000000;
        cursor: initial !important;
    }

    .follow-button {
        font-family: "Gilroy", sans-serif;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        height: 26px;
        border-radius: 10px;
        background: #ffffff;
        border: 1px solid #000000;
    }

    .modal-title {
        width: 100%;
    }

    .modal-content {
        border-radius: 20px;
    }

    .modal-header .btn-close {
        height: 22px;
        width: 14px;
        font-size: smaller;
        opacity: 1;
    }

    .ScrollbarsCustom {
        position: initial !important; 
    }

    .following-detail-modal-body {
        height: 410px;
        margin-bottom: 0.2rem;
    }

    .following-detail-modal-scrollbar {  
        .ScrollbarsCustom-Content {
            padding: 0 20px !important;
        }
    }
}