$triangleSize: 15px;
.lang-select-container {
    z-index: 1000;
}
.lang-select-tooltip {
    .triangle {
        z-index: 1;
        position: absolute;
        top: 15px;
        right: 5px;
        width: $triangleSize;
        height: $triangleSize;
        transform: rotate(45deg) translateY(0px) translatex(10px);
    }

    &-body {
        list-style: none;
        position: absolute;
        top: 30px;
        right: -15px;
        min-width: 160px;
        border-radius: 5px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }
}

.flag {
    background-image: url("../../../../../public/flags.png");
    background-position: -37px -318px;
    height: 17px;
    width: 23px;

    &-ad {
        background-position: -4px -3px;
    }
    &-ae {
        background-position: -37px -3px;
    }
    &-ag {
        background-position: -4px -24px;
    }
    &-am {
        background-position: -37px -24px;
    }
    &-ar {
        background-position: -4px -45px;
    }
    &-at {
        background-position: -37px -45px;
    }
    &-au {
        background-position: -4px -66px;
    }
    &-be {
        background-position: -37px -66px;
    }
    &-bf {
        background-position: -4px -87px;
    }
    &-bg {
        background-position: -37px -87px;
    }
    &-bo {
        background-position: -4px -108px;
    }
    &-br {
        background-position: -37px -108px;
    }
    &-ca {
        background-position: -4px -129px;
    }
    &-cd {
        background-position: -37px -129px;
    }
    &-cg {
        background-position: -4px -150px;
    }
    &-ch {
        background-position: -37px -150px;
    }
    &-cl {
        background-position: -4px -171px;
    }
    &-cm {
        background-position: -37px -171px;
    }
    &-cn {
        background-position: -4px -192px;
    }
    &-co {
        background-position: -37px -192px;
    }
    &-cz {
        background-position: -4px -213px;
    }
    &-de {
        background-position: -37px -213px;
    }
    &-dj {
        background-position: -4px -234px;
    }
    &-dk {
        background-position: -37px -234px;
    }
    &-dz {
        background-position: -4px -255px;
    }
    &-ee {
        background-position: -37px -255px;
    }
    &-eg {
        background-position: -4px -276px;
    }
    &-es {
        background-position: -37px -276px;
    }
    &-fl {
        background-position: -4px -297px;
    }
    &-fr {
        background-position: -37px -297px;
    }
    &-ga {
        background-position: -4px -318px;
    }
    &-en {
        background-position: -37px -318px;
    }
    &-gm {
        background-position: -4px -339px;
    }
    &-gt {
        background-position: -37px -339px;
    }
    &-hn {
        background-position: -4px -360px;
    }
    &-ht {
        background-position: -37px -360px;
    }
    &-hu {
        background-position: -4px -381px;
    }
    &-id {
        background-position: -37px -381px;
    }
    &-ie {
        background-position: -4px -402px;
    }
    &-il {
        background-position: -37px -402px;
    }
    &-in {
        background-position: -4px -423px;
    }
    &-iq {
        background-position: -37px -423px;
    }
    &-ir {
        background-position: -4px -444px;
    }
    &-it {
        background-position: -37px -444px;
    }
    &-jm {
        background-position: -4px -465px;
    }
    &-jo {
        background-position: -37px -465px;
    }
    &-jp {
        background-position: -4px -486px;
    }
    &-kg {
        background-position: -37px -486px;
    }
    &-kn {
        background-position: -4px -507px;
    }
    &-kp {
        background-position: -37px -507px;
    }
    &-kr {
        background-position: -4px -528px;
    }
    &-kw {
        background-position: -37px -528px;
    }
    &-kz {
        background-position: -4px -549px;
    }
    &-la {
        background-position: -37px -549px;
    }
    &-lb {
        background-position: -4px -570px;
    }
    &-lc {
        background-position: -37px -570px;
    }
    &-ls {
        background-position: -4px -591px;
    }
    &-lu {
        background-position: -37px -591px;
    }
    &-lv {
        background-position: -4px -612px;
    }
    &-mg {
        background-position: -37px -612px;
    }
    &-mk {
        background-position: -4px -633px;
    }
    &-ml {
        background-position: -37px -633px;
    }
    &-mm {
        background-position: -4px -654px;
    }
    &-mt {
        background-position: -37px -654px;
    }
    &-mx {
        background-position: -4px -675px;
    }
    &-na {
        background-position: -37px -675px;
    }
    &-ne {
        background-position: -4px -696px;
    }
    &-ng {
        background-position: -37px -696px;
    }
    &-ni {
        background-position: -4px -717px;
    }
    &-nl {
        background-position: -37px -717px;
    }
    &-no {
        background-position: -4px -738px;
    }
    &-om {
        background-position: -37px -738px;
    }
    &-pa {
        background-position: -4px -759px;
    }
    &-pe {
        background-position: -37px -759px;
    }
    &-pg {
        background-position: -4px -780px;
    }
    &-pk {
        background-position: -37px -780px;
    }
    &-pl {
        background-position: -4px -801px;
    }
    &-pt {
        background-position: -37px -801px;
    }
    &-py {
        background-position: -4px -822px;
    }
    &-qa {
        background-position: -37px -822px;
    }
    &-ro {
        background-position: -4px -843px;
    }
    &-ru {
        background-position: -37px -843px;
    }
    &-rw {
        background-position: -4px -864px;
    }
    &-sa {
        background-position: -37px -864px;
    }
    &-se {
        background-position: -4px -885px;
    }
    &-sg {
        background-position: -37px -885px;
    }
    &-sl {
        background-position: -4px -906px;
    }
    &-sn {
        background-position: -37px -906px;
    }
    &-so {
        background-position: -4px -927px;
    }
    &-sv {
        background-position: -37px -927px;
    }
    &-td {
        background-position: -4px -948px;
    }
    &-tj {
        background-position: -37px -948px;
    }
    &-tl {
        background-position: -4px -969px;
    }
    &-tr {
        background-position: -37px -969px;
    }
    &-tz {
        background-position: -4px -990px;
    }
    &-uk {
        background-position: -37px -990px;
    }
    &-us {
        background-position: -4px -1011px;
    }
    &-ve {
        background-position: -37px -1011px;
    }
    &-vn {
        background-position: -4px -1032px;
    }
    &-ye {
        background-position: -37px -1032px;
    }
}