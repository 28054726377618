.dropdown-filter {
    width: 10rem;
}
.dropdown-filter-toggle-btn {
    background: transparent;
    color: black;
    border: 1px solid #000000;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    width: 95% !important;
    font-size: 14px;
    height: 2.188rem;
}

.dropdown-filter-toggle-btn:focus,
.dropdown-filter-toggle-btn:hover {
    background: transparent;
    box-shadow: none;
    color: black;
}

.dropdown-menu {
    padding: 8px;
}

.search-filter-box {
    background: #ECECEC;
    border-radius: 8px;
    width: 16.25rem;
}

.attribute-item {
    background: transparent;
    border: none;
}

.custom-search-filter-menu {
    max-height: 12rem;
}

.attribute-item-section {
    overflow-y: scroll;
    height: auto;
    max-height: 8.2rem;
}

.attribute-item-section::-webkit-scrollbar {
    width: 0px;
}

.custom-search-text-filter-label {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}