.connect-filters-dropdown {
    margin-right: 14px !important;

    .dropdown-toggle::after {
        content: none;
    }

    .filter-btn {
        border-radius: 100%;
        display: flex;
        align-items: center;
        background: transparent;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        height: 2.375rem;
        border: none;

        .filter-counts {
            color: black;
        }

        .connect-filter-icon {
            height: 19px;
            width: 27px;
            color: black;
            font-size: 20px;
        }
    }

    .filter-btn:focus {
        box-shadow: none;
    }

    .filter-btn:hover {
        background-color: #EEEEEE;
    }

    .dropdown-btn {
        background-color: #ECECEC;
        box-shadow: none !important;
        outline: none !important;
    }

    .dropdown-menu {
        .connect-filters-header {
            font-size: 1.375rem;
            font-family: "Gilroy", sans-serif;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .clear-btn {
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 21px;
                background: transparent;
                border: none;
            }
        }

        .top-filter-section {
            padding: 8px 16px 0px 16px;
            display: flex;
        }

        .bottom-filter-section {
            padding: 0px 16px 8px 16px;
            display: flex;
        }

        .footer-section {
            padding: 0 16px;
            display: flex;
            justify-content: end;
        }

        .connect-search-icon {
            width: fit-content;
            border: none;
        }
    }
}