.search-section {
    background-color: #EEEEEE;
    border-radius: 10px;
    height: 37px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.search-icon {
    width: fit-content !important;
    border-right: none !important;
    background: transparent !important;
    outline: none !important;
    height: 37px;
    border: none !important;
}

.participants-search {
    width: 100%;
    height: 37px;
    background-color: transparent;
    border: transparent;
    outline: none;
    font-family: 'Gilroy';
}