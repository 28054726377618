@import "../../../styles/custom.scss";
@import "../../../styles/mixins.scss";

$top-navbar-height: 70px;
$left-sidebar-width: 85px;
$page-content-offset: 20px;
$menuImageSize: 42px;

#page-frame-top {
  height: $top-navbar-height;
  z-index: 10;

  @include breakpoint(xs) {
    padding-right: 0px !important;
  }

  .logo-img-size {
    width: 140px;
    height: $top-navbar-height;
  }
}

#page-frame-left {
  z-index: 10;
  margin-top: $top-navbar-height;
  width: $left-sidebar-width;
  top: 0;
  left: 0;
  bottom: 0;

  svg {
    font-size: 35px;
  }

  .navbar {
    .container-fluid {
      padding: 0 !important;
      height: 100%;
    }

    .nav-link {
      hr {
        opacity: 1;
        color: $light-gray;
      }
    }
  }
}

#page-frame-contents {
  overflow-y: auto;
  top: 0;
  padding: $page-content-offset;
  padding-top: $top-navbar-height + $page-content-offset;
  padding-left: $page-content-offset;
  background-color: #f3f3f3;

  @media (min-width: 576px) {
    padding-left: $left-sidebar-width + $page-content-offset;
  }
}

#page-frame-right-wrapper {
  width: 450px;
  overflow: hidden;

  @include breakpoint(xs) {
    width: 80%;
  }

  #page-frame-right {
    z-index: 10;
    width: 450px;

    @include breakpoint(xs) {
      width: 100%;
    }
  }

  .profile-description {
    display: flex;
    align-items: center;
    padding: 25px 30px;
  }
}

#page-search-bar {
  top: -$top-navbar-height;
  left: -100%;
  height: $top-navbar-height;
  padding-left: 250px;

  @include breakpoint(xs) {
    padding-left: 0;
  }

  input {
    &::placeholder {
      color: var(--placeholder-color, white);
    }
  }
}

.menu-image,
.menu-image svg {
  max-width: $menuImageSize;
  max-height: $menuImageSize;
  display: block;
  margin: 0px auto;
}

.menu-image svg {
  visibility: hidden;
}

.esg-icon {
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin: 0px 0px 5px 0px;
}

.unread-threads-count {
  top: -3px;
  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  padding-top: 0px;
}

.unread-counter {
  left: 0px;
  top: 0px !important;
  font-size: 10px;
  width: 22px;
  height: 22px;
}

.unread-icon {
  height: 22px;
  width: 22px;
}

.form-control-feedback {
  height: 30px;
  top: 50%;
  margin-top: -15px;
  line-height: 30px;
  margin-right: 10px;
  font-size: 1.5em;
  pointer-events: auto;
  position: absolute;
  right: 0;
  display: block;
  width: 34px;
}

.right-pop-icon {
  width: 30px;
}

#page-frame-top .container-fluid {
  flex-wrap: nowrap !important;
}

@media (max-width: 575px) {
  .top-nav-title {
    display: none;
  }
}

@media (max-width: 575px) {
  .logo-img-size {
    width: 100px !important;
    height: 50px !important;
  }
}

@media (max-width: 400px) {
  .logo-img-size {
    width: 70px !important;
    height: 35px !important;
  }
}