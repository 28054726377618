.no-btn {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    box-sizing: border-box;
    width: 102px;
    height: 44px;
    box-shadow: none !important;
}

.yes-btn {
    border-radius: 8px;
    width: 102px;
    height: 44px;
    box-shadow: none !important;
}

.leave-group-header {
    padding-left: 0;
    border-bottom: 0px !important;
}

.leave-group-msg {
    font-size: 14px;
    margin-bottom: 0;
}

.leave-group-modal {
    max-width: 505px !important;

    .modal-title {
        color: black;
    }

    .modal-content {
        border-radius: 20px !important;
    }

    button {
        border-radius: 8px;
    }
}