@import "../../../../styles/custom.scss";

.sign-out {
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 10px 0px;
  height: 100%;
  top: 90%;
  position: absolute;
  display: inline-flex;
}
.menu-text {
  font-size: 16px;
  cursor: pointer;
  margin-top: 13px;
}