.profile-awards-section {
    overflow-y: auto;
    overflow-x: hidden;
    
    .profile-card {
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        border-radius: 10px;

        .loader-card-body {
            height: 15.5rem;
        }

        .profile-background-image {
            height: 100px;
            border-bottom: 1px solid #dee2e6;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;
        }
        .profile-row {
            top: 50px;
        }

        .user-award {
            height: 1.5rem;
        }

        .send-thanku-icon {
            font-size: 25px;
        }

        .user-profile-img {
            border-radius: 100%;
            height: 90px;
            width: 90px;
        }
        .user-followers {
            cursor: pointer;
        }

        .following-column {
            border-left: 1px solid #dee2e6;

            .user-following {
                cursor: pointer;
            }
        }
    }

    .no-awards {
        height: calc(100vh - 471px);
    }

    .no-awards.no-job-title {
        height: calc(100vh - 452px);
    }

    .with-awards {
        height: calc(100vh - 502px);
    }

    .with-awards.no-job-title {
        height: calc(100vh - 483px);
    }

    .awards-card {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        border-radius: 10px;
        overflow: hidden;

        .award-img {
            width: 100%;
            border-radius: 4px;
        }

        .awards-inbox::-webkit-scrollbar {
            width: 0px;
        }

        .awards-inbox {
            height: calc(100vh - 550px);
            overflow-y: scroll;
        }
    }
}

.profile-awards-section::-webkit-scrollbar {
    width: 0px;
}

.no-awards-content {
    padding: 50px;
    font-size: 20px;
    text-align: center;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .font-primary-text {
        font-size: 1rem !important;
    }
    .font-secondary-text {
        font-size: 0.8rem !important;
    }
}