$primary: #0091b8 !default;
$primary-light: #9cd3e2 !default;
$secondary: #294f60 !default;
$light-black: #656565 !default;
$light-gray: #efefef !default;
$dark-gray: #b2b2b2 !default;
$darker-gray: #797979 !default;
$badge-secondary: #6c757d !default;
$alert: #fff3cd !default;
$alert-warning: #856404 !default;
$white-smoke: #F5F5F5 !default;
$link-hover: #006985 !default;
$green: #47a58d !default;
$off-white: #f4f4f4 !default;
$backgroundsGray: #fff !default;

$colors: (
    "primary": $primary,
    "primary-light": $primary-light,
    "secondary": $secondary,
    "light-black": $light-black,
    "light-gray": $light-gray,
    "dark-gray": $dark-gray,
    "darker-gray": $darker-gray,
    "alert": $alert,
    "alert-warning": $alert-warning,
    "white-smoke": $white-smoke,
    "badge-secondary": $badge-secondary,
    "green": $green,
    "off-white": $off-white,
    "backgrounds-gray":  $backgroundsGray
);

@each $color-name, $color-code in $colors {
    .text-#{$color-name} {
        color: $color-code !important;
    }
}

@each $color-name, $color-code in $colors {
    .bg-#{$color-name} {
        background-color: $color-code !important;
    }
}

@each $color-name, $color-code in $colors {
    .border-#{$color-name} {
        border-color: $color-code!important;
    }
}
