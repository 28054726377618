@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: url(../../public/fonts/Gilroy/Gilroy-ExtraBold.otf);
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: url(../../public/fonts/Gilroy/Gilroy-Light.otf);
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: url(../../public/fonts/Gilroy/EM-Gilroy_Gilroy-Bold.ttf);
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: url(../../public/fonts/Gilroy/EM-Gilroy_Gilroy-SemiBold.ttf);
}
body {
  font-family: "Gilroy";
}
