.thread-preview-section {
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
}

.thread-icon-section {
    font-size: 12px;
    font-weight: 300;
    padding-right: 5px;
    padding-left: 5px;
    white-space: nowrap;
}

.thread-icon {
    font-size: 12px;
    cursor: default;
    outline: none;
}

.tool-tip {
    z-index: 9999;
}