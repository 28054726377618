@import './../../../../styles/custom.scss';

.nav .li{
    color: #294f60;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 45px;
}
.rowItem .locationIcon {
    width: 25px;
    height: 34px;
    float: left;
    text-align: center;
    line-height: 50px;
    color: #000000;
    font-style: normal;
}
#root [id="page-frame-contents"] .mb0 {
    margin-bottom: 0;
}
h3 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.2;
}
a.rowItem {
    text-decoration: none;
    background-color: transparent;
}
#root [id="page-frame-contents"] .align-items-center {
    align-items: center;
}
#root [id="page-frame-contents"] .mt0 {
    margin-top: 0;
}
.atozlist .list {
    width: 15px;
    float: right;
}
#root [id="page-frame-contents"] .w-5 {
    width: 5%;
}
.flex-direction-column {
    flex-direction: column;
}
#root [id="page-frame-contents"] .justify-content-center {
    justify-content: center;
}
#root [id="page-frame-contents"] .pb {
    padding-bottom: 10px;
    padding-top: 6px;
}
#root [id="page-frame-contents"] .d-flex {
    display: flex;
}
.d-flex {
    display: flex;
}
#root [id="page-frame-contents"] .mr {
     margin-right: 10px; 
}
.mr {
    margin-right: 10px; 
}
*, *::before, *::after {
    box-sizing: border-box;
}
#root [id="page-frame-contents"] .slimScrollDiv {
    width: 100%;
}
.atozlist .topnav .nav a {
    width: 32%;
    color: #294f60;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 45px;
    margin-bottom: 5px;
}
.atozlist .topnav .nav a.active {
    border-bottom: #294f60 5px solid;
}
#root [id="page-frame-contents"] .ml-sm {
    margin-left: 5px;
}
.atozlist .rows {
    padding: 15px 15px 0px 15px;
}
.connect .location-heading {
    line-height: 50px;
}
#root [id="page-frame-contents"] .cursor-pointer {
    cursor: pointer;
}
.location-search-list {
    margin-top: 0px !important;
}
#root [id="page-frame-contents"] .search-list h3 {
    padding: 50px;
}
.ScrollbarsCustom-Content .profile .user-awards {
    right: 22px;
}
.topnav .nav a {
    font-weight: 700;
    text-align: center;
    color:#294f60;
    text-decoration: none;
    padding: 10px;
}
.details {
    overflow-x: hidden;
    overflow-y: scroll;
}

.details-container {
    height: 100%;
}

#root [id="page-frame-contents"] .feed-list-container .listing-scroll{
    padding-top: 13px;
    height: calc(100% - 650px);
}
.location-item {
    border-bottom: 1px solid white;
}
.location-title {
    padding: 10px;
    border-radius: 10px 10px 0 0;
    text-align: center;
    color: $secondary;
    font-size: 14pt;
    background: white;
    text-align-last: center;
    color: #000000;
    font-weight: 600 !important;
}
.attribute-title {
    font-weight: 700;
    font-size: 12pt;
}

.active-location-tab {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color:#ffffff !important;
    Font: "Gilroy-SemiBold";
    font-size: 16px !important;
    font-weight: 600;
    pointer-events: none;
}

.locations-tab {
    border-top-right-radius: 8px !important;
    font-size: 16px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.inactive-contact-tab {
    border-top-left-radius: 8px !important;
    Font: "Gilroy-SemiBold";
    font-size: 16px;
    font-weight: 600;
}

.location-search-section {
    background-color: #EEEEEE;
    border-radius: 10px;
    height: 37px;
    width: 100%;
}

.location-search-icon {
    width: fit-content !important;
    border-right: none !important;
    background: transparent !important;
    outline: none !important;
    height: 37px;
    border: none !important;
}

.location-search-input {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: transparent;
    outline: none;
    font-family: 'Gilroy';
}

.location-search-input::placeholder {
    font-size: 18px;
    Font: "Gilroy";
}

.active-location-icon {
    height: 21px;
    width: 20px;
}

@media (max-width: 767px) {
    .location-title {
        font-size: 14px !important;
    }

    .location-search-input::placeholder {
        font-size: 14px !important;
    }
}