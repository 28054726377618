.user-group-btn {
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: transparent;
    height: 68px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.icon-section {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(21, 56, 91);
    border-radius: 100%;
    margin-right: 10px;
}

.user-group-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Gilroy";
    margin-left: 5px;
}