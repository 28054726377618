.search-icon {
    background: #ECECEC;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: fit-content;
    padding-right: 0px;
}

.search-box {
    border-left: none;
    width: 100%;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-radius: 8px;
}

.search-box:focus {
    width: 100%;
    box-shadow: none;
    border: 1px solid #ced4da !important;
    border-left: none !important;
}

.badge-color {
    width: 18px;
    height: 18px;
    border-radius: 100%;
}

.custom-search-filter-menu {
    max-height: 12rem;
}

.filter-item {
    height: auto;
    max-height: 8.2rem;
}

.custom-search-filter-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-search-pick-list-scrollbar {
    position: relative !important;

    .ScrollbarsCustom-Scroller {
        position: relative !important;
    }

    .ScrollbarsCustom-Scroller::-webkit-scrollbar {
        width: 0px;
    }
}