#root {
    $text-color: #212529;
    $btn-hover-color: null;
}

@media only screen and (max-width: 991px) {
    .login-wrapper {
        .main-container {
            height: 100%;
        }
        .content {
            .login-logo {
                height: 100px;
                width: 100px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .login-wrapper {
        overflow: auto;

        .logo-wrapper {
           visibility: visible !important;
        }

        .row {
            overflow-y: auto;
        }

        .left-img {
            height: 150px;
        }

        .left-section{
            display: none !important;
        }

        .left-logo-img {
            width: 100px !important;
            border-radius: 100%;
        }

        .comp-left-img {
            height: 50% !important;
            width: 150px !important;
        }

        .content {
            max-width: 400px !important;
            .login-logo {
                height: 130px;
                width: 130px;
            }
        }
    }

    .login-wrapper::-webkit-scrollbar, .login-row::-webkit-scrollbar {
        width: 5px !important;
        background-color: white !important;
    }
    .login-wrapper::-webkit-scrollbar-track, .login-row::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px white !important;
    }
    
    .login-wrapper::-webkit-scrollbar-thumb, .login-row::-webkit-scrollbar-thumb {
        background-color: #8c8c8c !important;
        border-radius: 2px !important;
    }
}

@media only screen and (max-width: 575px) {
    .login-wrapper {
        overflow: auto;
        .content {
            .login-logo {
                height: 100px;
                width: 100px;
            }
        }
    }
}

#login-page {
    height: 100% !important;
}

.back-btn {
    position: absolute;
    cursor: pointer;
    color: black;
    text-decoration: none;
    z-index: 1;
}

.login-wrapper {
    .logo-wrapper {
        visibility: hidden;
    }

    .left-img {
        height: 100vh;
        object-fit: cover;
    }

    .main-container {
        height: 100%;
    }

    .left-logo-img{
        height: auto !important;
        width: 25%;
    }

    .comp-left-img {
        height: auto;
        width: 25%;
    }

    .left-section{
        display: flex;
        justify-content: center;
    }
    
    .hello-title {
        font-weight: bold;
        font-size: 35px;
    }

    .next-button, .login-button, .new-user-button {
        background-color: #8c8c8c;
        border-color: #8c8c8c;
    }
    .login-buttons:hover {
        background-color: var(--btn-hover-color) !important;
    }
    .login-logo {
        height: 150px;
        width: 120px;
    }

    .line {
        width: 100%;
        background-color: #8c8c8c;
        height: 1px;
    }

    .placeholder-font::placeholder {
        color: var(--text-color);
    }

    .or-keyword {
        z-index: 1;
        width: 10%;
        display: flex;
        justify-content: center;
        color: #8c8c8c;
    }
    .content {
        max-width: 80%;
        .message {
            font-size: 1rem;
        }
    }
}


 .login-spinner {
    background: rgb(249,250,252);
    background: radial-gradient(circle, rgba(249,250,252,1) 0%, rgba(223,228,234,1) 50%, rgba(187,200,209,1) 100%);
}

.input-password {
    padding-right: 10% !important;
}

.eye-button {
    margin-top: 1%;
    right: 3%;
}

.disable-right-panel {
    pointer-events: none;
    opacity: 0.7;
}
