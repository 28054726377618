.customLayoutBanner {
    flex: 3;

    ol.carousel-indicators {
        margin-right: auto;
        margin-left: auto;
        bottom: 80px;
    }

    .carousel-indicators {
        padding-bottom: 20px;
    }

    .carousel-indicators li {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #869791;
        opacity: 1;
        border: 0;
        margin: 4px 6px;
        cursor: pointer;

        &.active {
            background: white;
        }
    }

    .carousel-control-prev, .carousel-control-next {
        width: 5vh
    }

    .banner-indices {
        pointer-events: none;
        bottom: var(--banner-indices-bottom-value);
        margin-bottom: 5px !important;
    }

    .banner-image {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 32/7;
        cursor: pointer;
        transition: .7s ease;
        backface-visibility: hidden;
        opacity: 1;
    }

    .banner-item {
        z-index: 0;
    }

    .banner-title-text {
        font-size: 25px;
        text-align: center;
        width: 100%;
        font-weight: 600; //semi-bold!
    }

    .banner-item:hover {
        background-color: black;
        z-index: 3;
    }

    .carousel-inner {
        border-radius: 10px;
    }

    .banner-item:hover .banner-image {
        opacity: 0.4;
        border-radius: 10px 10px 0px 0px;
    }

    .banner-item:hover {
        opacity: 1;
        border-radius: 10px 10px 0px 0px;
    }

    .banner-title {
        color: black;
        justify-content: center;
        background-color: white;
        border-radius: 0px 0px 10px 10px;
        min-height: 6vh;
        display: flex;
        align-items: center;
    }

    .banner-item-no-title:hover {
        opacity: 1;
        background-color: black;
        z-index: 3;
        border-radius: 10px;
    }

    .banner-item-no-title:hover .banner-image {
        opacity: 0.4;
        border-radius: 10px;
    }
}
