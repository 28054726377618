@mixin boxShadowDropdown {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

@mixin noBorder {
  border: none;
}

.filter-panel-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  div.input-group {
    margin-top: 0;
    width: 215px;

    input {
      @include boxShadowDropdown;
      @include noBorder;
    }
  }

  div.dropdown {
    button.filter-panel-dropdown-button {
      background-color: white;
      font-weight: 600;
      color: #656565;
      @include noBorder;
      @include boxShadowDropdown;
    }
  }

  div.dropdown.show {
    button.filter-panel-dropdown-button {
      background-color: white;
      color: #656565;
      @include noBorder;
    }
    div[aria-hidden="false"] {
      transform: translate3d(0px, 45px, 0px) !important;
      @include noBorder;
      @include boxShadowDropdown;
    }
  }
}
