.image-card-container {
    display: flex;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.image-card-img {
    width: 240px;
    aspect-ratio: 16/9;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 0px !important;
    padding-top: 0px !important;

    img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        object-fit: cover;
    }
}

.image-card-content {
    padding: 10px 20px;
}


.description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MuiBreadcrumbs-li > a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
