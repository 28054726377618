.notifications-container {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    opacity: .85;

    .notification {
        min-width: 150px;
        max-width: 300px;
        border-radius: 10px;
        transition: '1s';
        margin-right: 30px;
        line-height: 1rem;

        &.closed {
            margin-right: 0;
        }
    }
}