@import "src/styles/custom.scss";

.upload-file {
  color: #ffffff;
  background-color: $primary;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.ml-30 {
  margin-left: 30px;
}
.toggle-aspect-button, .toggle-aspect-button:hover, .toggle-aspect-button:focus {
  color: white;
  background-color: $primary;
  padding: 5px;
  margin-left: 10px;
}
.canvas-preview {
  border: 1px solid black;
  object-fit: contain;
  width: 500px;
  height: 500px;
}
.header-button {
  position: absolute;
  right: 17px;
  top: 17px;
}
.header-name {
  font-size: 20px;
  font-weight: 700;
  color: $secondary
}
.inline-inputs {
  width: 48%;
}
.rotate-input-properties {
  margin-left: 4%
}
.image-centered {
  display: flex;
  margin-left: auto;
  margin-right: auto
}