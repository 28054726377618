@import '../../../styles/custom.scss';

#root [id="page-frame-contents"] {
    overflow-y: auto;
}

.nav .li {
    color: $secondary;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 45px;
}

.rowItem .locationIcon {
    width: 25px;
    height: 34px;
    float: left;
    text-align: center;
    line-height: 50px;
    color: #000000;
}

#root [id="page-frame-contents"] .mb0 {
    margin-bottom: 0;
}

h3 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.2;
}

a.rowItem {
    text-decoration: none;
    background-color: transparent;
}

#root [id="page-frame-contents"] .align-items-center {
    align-items: center;
}

#root [id="page-frame-contents"] .mt0 {
    margin-top: 0;
}

.list {
    width: 15px;
    float: right;
}

#root [id="page-frame-contents"] .w-5 {
    width: 5%;
}

.flex-direction-column {
    flex-direction: column;
}

#root [id="page-frame-contents"] .justify-content-center {
    justify-content: center;
}

#root [id="page-frame-contents"] .pb {
    padding-bottom: 6px;
}

#root [id="page-frame-contents"] .d-flex {
    display: flex;
}

#root [id="page-frame-contents"] .mr {
    margin-right: 10px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

#root [id="page-frame-contents"] .slimScrollDiv {
    width: 100%;
}

.topnav .nav a {
    width: 32%;
    color: $secondary;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 45px;
    margin-bottom: 5px;
}

#root [id="page-frame-contents"] .ml-sm {
    margin-left: 5px;
}

.connect .location-heading {
    line-height: 50px;
}

#root [id="page-frame-contents"] .cursor-pointer {
    cursor: pointer;
}

#root [id="page-frame-contents"] .search-list h3 {
    padding: 50px;
}

.ScrollbarsCustom .trackYVisible {
    height: auto !important;
}

.ScrollbarsCustom-Content .user-awards {
    position: absolute;
    right: 35px;
}

.topnav .nav a {
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    padding: 5px 0 5px 0;
}

.active-list-item {
    width: 5px;
    margin-left: 5px;
    background-color: $primary;
}

#root [id="page-frame-contents"] .bg-engage-primary-dark {
    background: #428bca;
}

.contact-page {
    width: 100% !important;
}

.disable-tyc {
    opacity: 0.4;
}

.connect-container,
.location-details-container {
    background-color: white;
    border-radius: 8px;
    height: 86vh;
}

.profile-container {
    background-color: #f2f2f2;
    height: 86vh;
    border-radius: 8px;
}

.fa-star {
    font-style: normal;
}

.not-clickable {
    pointer-events: none;
    cursor: default;
}

.contacts-tab {
    border-top-left-radius: 8px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    Font: "Gilroy-SemiBold";
    font-size: 16px;
    pointer-events: none;
}

.active-contact-tab {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255) !important;
    Font: "Gilroy-SemiBold";
    font-size: 16px;
    font-weight: 600;
}

.inactive-location-tab {
    border-top-right-radius: 8px !important;
    Font: "Gilroy-SemiBold";
    font-size: 16px;
    font-weight: 600;
}

.contact-icon {
    height: 21px;
    width: 20px;
}

.location-icon {
    height: 21px;
    width: 20px;
}

.connect-search-section {
    background-color: #EEEEEE;
    border-radius: 10px;
    height: 37px;
    width: 100%;
}

.connect-search-icon {
    width: fit-content !important;
    border-right: none !important;
    background: transparent !important;
    outline: none !important;
    height: 37px;
    border: none !important;
}

.connect-search-input {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: transparent;
    outline: none;
    font-family: 'Gilroy';
}

.connect-search-input::placeholder {
    font-size: 16px;
    Font: "Gilroy";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

.favorite-title {
    Font: "Gilroy-SemiBold";
    font-size: 18px !important;
    text-align: left;
    color: #000000;
    font-weight: 600 !important;
}

.fav-section {
    width: 100%;
    height: fit-content;
}

.section-title {
    margin-left: 5px;
    margin-top: 16px;
    color: #000000;
    font-weight: 600 !important;
}

.contact-user-name {
    Font: "Gilroy-SemiBold";
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 8px;
    word-break: break-all;
}

.contact-job-title {
    Font: "Gilroy";
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    color: #000000;
}

.all-contact-title {
    Font: "Gilroy-SemiBold";
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0em;
    color: #000000;
    font-weight: 600 !important;
}

.list-char-item {
    color: #000000;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    Font: "Gilroy-SemiBold";
}

.connect-search-filter-grid {
    padding-left: 0px !important;
}

.connect-search-filter-grid-container {
    margin-left: 0px !important;
}

.awards-section {
    display: flex;
}

.awards-count {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 24px !important;
    height: 24px !important;
    font-size: 10px;
}

.awards-count-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.favorite-icon {
    font-size: 23px !important;
}

.search-list {
    margin-top: 24px !important;
    width: auto !important;
    padding-right: 5px !important;
}

.awards-count-span {
    padding-right: 2px !important;
}

.all-contacts {
    padding-right: 5px !important;
    padding-top: 10px !important;
}

@media (max-width: 767px) {
    .contact-user-name {
        font-size: 14px !important;
    }

    .contact-job-title {
        font-size: 12px !important;
    }

    .connect-display-image {
        height: 40px !important;
        width: 40px !important;
        min-height: 40px !important;
        min-width: 40px !important;
    }

    .rowItem .locationIcon {
        height: 30px !important;
    }

    .connect-search-input::placeholder {
        font-size: 14px !important;
    }
}