@import "src/styles/custom.scss";

.header-chart {
  font-weight: 700;
  font-size: 15pt;
  color: $secondary;
}
.close-btn {
  position: absolute;
  right: 17px;
  top: 17px;
}
.button-colour {
  background-color: $green;
}
.tree-node-img {
  vertical-align: center;
  border: 2px solid white;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}
.node-element {
  display: grid;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.node-element:hover {
  color: white;
}

.org-chart-container {
  overflow: auto;
  margin: 15px 0 15px 0;
  background-color: white;
  border-radius: 8px;
}
.org-chart-label {
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  background-color: #294f60;
  min-width: 200px;
  min-height: 135px;
  &.root-node {
    background-color: #0091b8;
  }
}

#close-awards-list {
  border-radius: 20px;
}
