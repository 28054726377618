.reject-btn {
    background-color: #FFFFFF !important;
    border: 2px solid #15385B;
    border-radius: 8px;
    color: #15385B !important;
    box-sizing: border-box;
}

.accept-btn {
    background: #15385B;
    border-radius: 8px;
}

.notification-header {
    padding-left: 0;
    border-bottom: 0px !important;
}

.notification-msg {
    font-size: 14px;
    margin-bottom: 0;
}