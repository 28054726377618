@import '../../../styles/custom.scss';

$button-colour: #ecf0f1;

.feed-items-container {
    .latest-comments-list {
        border: none;
        border-radius: 10px;
        .comment {
            .comment-time {
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }
    }

    .preview-image {
        width: 256px;
        height: 144px;
        object-fit: contain;
        border-radius: 4px;
    }

    .icon-class {
        background: no-repeat 100% 0px;
        background-size: 48px;
    }

    .page-icon {
        background: url(https://cdn.engageesp.com/img/feed/page.png) no-repeat 100% -1px;
        background-size: 48px;
    }
    .pinned-icon {
         background: url(https://cdn.engageesp.com/img/feed/pinned.png) no-repeat 100% -1px;
         background-size: 48px;
     }
    .notification-icon {
        background: url(https://cdn.engageesp.com/img/feed/notification.png) no-repeat 100% -2px;
        background-size: 48px;
    }

    .grey-box {
        background-color: #e5e5e5;
        border-radius: 10px;
    }

    .feed-item-header {
        .feed-item-icon {
            right: 0.6rem;
        }
    }

    .item-container {
        border-radius: 10px;
        border: none;
    }

    .social-post-item {
        .social-post-message {
            font-size: 12pt;

            a {
                text-decoration: none;
                color: inherit;
                font-weight: bold;
            }

            p {
                margin-bottom: 0;
            }
        }

        .sp-comment-item {
            .user-profile-image {
                top: 0;
                left: 0;
            }

            .sp-comment-body {
                border-radius: 10px;
                background-color: $off-white;
            }
        }
    }
}

.page-footer-option {
    font-size: 1rem;
}

.social-post-likes-modal {
    .modal-body {
        height: 500px;
    }
}

.react-confirm-alert-overlay {
    background: hsla(0, 0%, 27%, 0.2);
}

.react-confirm-alert {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
}

.react-confirm-alert-body {
    width: 100% !important;
    font-family: inherit;
    box-shadow: 0 3px 3px 0 hsla(0, 0%, 27%, 0.4);
    padding: 11px;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 5px;
    h1 {
        font-weight: 400 !important;
        font-size: 1.5rem !important;
    }
}

.comment-btn-primary {
    color:#008651;
  }
  
.comment-btn {
    font-style: normal;
}

.react-confirm-alert-button-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-shadow: none;
    button {
        text-transform: uppercase;
        background-color: $button-colour;
        color: black;
        transition: background 0.2s;
        font-size: 14px;
        font-weight: bold;
    }
    button:first-of-type {
        margin-left: auto;
    }
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-right-20 {
    padding-right: 20px;
}

.social-post-message-font {
    font-size: 13pt;
}

.feed-share-btn {
    width: 100%;
    border-radius: 5% !important;
}

.feed-page-thumb {
    height: 55px!important;
    min-height: 55px!important;
    min-width: 55px!important;
    width: 55px!important;
}

.media-news {
    flex-direction: column !important;
}

.img-rad {
    border-radius: 4px;
}

.no-data-container {
    height: calc(100vh - 100px);
}

.no-data-container-no-sp {
    height: calc(100vh - 110px);
}

.no-data-content {
    margin: 25px;
    font-size: 20px;
    text-align: center;
}

.tyc-btn-text {
    padding-top: 3px;
}

h4.original-comment {
    background-color: #e8e8e8;
    border-radius: 8px;
    padding: 0.25rem;
}

@media (max-width: 992px) {
    .profile-section, .comments-section {
        display: none;
    }
}

@media (min-width: 576px) {
    .react-confirm-alert {
        max-width: 270px !important;
    }  
    .media-news {
        flex-direction: row !important;
    }  
}

@media (min-width: 768px) {
    .react-confirm-alert {
        max-width: 380px !important;
    }    
}

@media (min-width: 992px) and (max-width: 1200px) {
    .user-info {
        padding-left: 6%;
    }

    .font-primary-text {
        font-size: 1rem !important;
    }

    .font-secondary-text {
        font-size: 0.8rem !important;
    }
}

.social-post-item {
    .social-post-message {
        p {
            margin-bottom: 0 !important;
        }
    }
}

.custom-layout-scrollbar {
    margin-right: 16px;
}