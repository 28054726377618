.files-list-modal {

    .modal-header {
        border-bottom: 0px;
    }

    .modal-content {
        width: 700px !important;
        border-radius: 20px;
    }

    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .awards-modal-title {
        Font: "Gilroy-Bold";
        font-size: 20px;
        font-weight: 600;
    }

    .modal-body {
        padding: 0px 8px 0px 0px;
    }

    .text-black {
        color: #000000 !important;
    }

    .close-awards-list {
        opacity: 1 !important;
    }

    .award-modal-search-section {
        background-color: #EEEEEE;
        border-radius: 10px;
        height: 37px;
        width: 100%;
    }

    .award-modal-search-icon {
        width: fit-content !important;
        border-right: none !important;
        background: transparent !important;
        outline: none !important;
        height: 37px;
        border: none !important;
    }

    .awards-modal-search-input {
        width: 100%;
        height: 45px;
        background-color: transparent;
        border: transparent;
        outline: none;
        font-family: 'Gilroy';
    }

    .awards-modal-search-input::placeholder {
        font-size: 16px;
        Font: "Gilroy";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000000;
    }

    .award-image {
        height: 100px;
        width: 100px;
    }

    .award-badge-image {
        height: 56px;
        width: 56px;
    }

    .award-title {
        Font: "Gilroy-SemiBold";
        font-size: 16px;
        font-weight: 600;
        word-break: break-all;
    }

    .awarded {
        Font: "Gilroy";
        font-size: 14px;
        font-weight: 400;
        word-break: break-all;
    }

    .award-text {
        Font: "Gilroy";
        font-size: 14px;
        font-weight: 400;
    }

    .user-awards-list {
        padding-left: 0px !important;
    }

    .slimScrollDiv::-webkit-scrollbar {
        width: 5px !important;
        background-color: white !important;
    }
    .slimScrollDiv::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px white !important;
    }
    
    .slimScrollDiv::-webkit-scrollbar-thumb {
        background-color: #8c8c8c !important;
        border-radius: 2px !important;
    }
}
