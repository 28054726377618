.mention-link {
    cursor: pointer;
    color: black;
    font-weight: bold;
    &:hover {
        color: inherit;
    }
}

.read-only {
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

.public-DraftEditorPlaceholder-root {
    color: #bdc1c9 !important;
}

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}
