@import "./custom.scss";
@import "./bootstrap/bootstrap.scss";
@import "./fonts.scss";
@import "react-tooltip/dist/react-tooltip.css";
@import "./chat.scss";


//  9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem (base)
// 18px = 1.125rem

body,
#root {
    height: 100vh;
    font-family: "Gilroy", sans-serif;
    font-size: 0.875rem !important;
    overflow: hidden;

    h3 {
        font-size: 1.125rem;
    }
}

.alert {
    &-warning {
        background-color: $alert;
        color: $alert-warning;
        border: 1px solid #ffeeba;
    }
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.25rem;
}

h3 {
    font-size: 1.125rem;
    font-weight: bold;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.75rem;
}

h6 {
    font-size: 0.625rem;
}

$font-sizes: (
    "small": small,
    "smaller": smaller,
    "10": 10px,
    "12": 12px,
    "13": 13px,
    "14": 14px,
    "15": 15px,
    "16": 16px,
    "20": 20px,
    "22": 22px,
    "24": 24px,
    "36": 36px,
    "48": 48px
);

@each $font-name,
$font-code in $font-sizes {
    .font-#{$font-name} {
        font-size: $font-code !important;
    }
}