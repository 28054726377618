@import '../../../../../styles/custom.scss';

.comment-input-comp {
    .user-img {
        left: 0;
    }
    .input-area {
        background-color: rgba($light-black, .1);
        margin-left: 50px;
        border-width: 1px;
        border-color: $primary;
        border-style: solid;
        border-radius: 10px;
        width: calc(100% - 50px);
        padding-left: 10px;
        outline: none;
        padding-right: 6rem !important; 
        overflow-y: scroll;
        white-space: pre-wrap;
        min-height: 48px !important; 
        max-height: 113px !important;
        resize: none;
        padding-top: 12px;
        padding-bottom: 10px;
        height: 45px;
        margin-bottom: 0px;

        .DraftEditor-root {
            height: auto !important;
        }
    }

    .input-area::-webkit-scrollbar {
        width: 0px;
    }
    
    .media-elements {
        right: 5.5em;
        top: calc(1rem - 2px);
    }
    .send-icon {
        font-size: 20px;
        top: calc(1rem - 2px);
        right: 1.2rem;
    }

    .attachment-icon {
        height: 20px;
        vertical-align: top;
        filter: invert(36%) sepia(92%) saturate(1127%) hue-rotate(162deg) brightness(92%) contrast(101%)
    }
}
.img-preview-sp {
    width: 200px;
}