.pagination-item.active .pagination-link {
    background: #15385B;
    border-radius: 4px;
    border: none !important;
    box-shadow: none !important;
}

.pagination-link:active {
    border: none !important;
}

.pagination-link:focus {
    box-shadow: none !important;
}

.pagination-link,
.pagination-link:hover {
    background: #FFFFFF;
    border: 0.8px solid #000000;
    border-radius: 4px;
    color: black;
}

.prev-page-btn,
.next-page-btn {
    background: transparent;
    border: none;
}

.prev-page-btn:focus,
.next-page-btn:focus {
    box-shadow: none !important;
}

.next-page-icon, .prev-page-icon {
    height: 15px;
    width: 9px;
    color: black;
    font-size: 18px;
}