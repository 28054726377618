.custom-layout-latest-comments {
  height: 100%;
  .latest-comments-list {
    border-radius: 10px;
    border: none;
  }
  ul.list-group {
    overflow-y: auto;
    height: 100%;
  }
  p.list-group-item-text {
    overflow-wrap: break-word;
  }
  .list-group-item {
    padding: 0.7rem 1rem;
  }
  .comment-value, .comment-timestamp, .author-name, .profile-icon {
    cursor: pointer;
  }
  .mention-link {
    text-decoration: none;
    color: inherit;
  }
}


@media (min-width: 992px) and (max-width: 1200px) {
  .user-comment-detail {
    flex-direction: column;
    align-items: flex-start !important;

    .timestamp {
      margin-left: 0 !important;
    }

    .font-primary-text {
      font-size: 1rem !important;
    }
  }
  .font-secondary-text {
    font-size: 0.8rem !important;
  }
}