@import "../../../styles/custom.scss";

.breadcrumb-wrapper {
  ol.breadcrumb {
    padding: 20px 0px 5px 5px;
  }

  hr.solid {
    border-top: 3px solid;
  }
}

.breadcrumb-item-a {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
}
