@import "../../../styles/custom.scss";

.cookies-modal > .modal-content{
  border-radius: 23px!important;
  border: none!important;
}

.modal-header.cookies-header{
  background-color: #61717B;
  justify-content: center;
  border-radius: 20px 20px 0px 0px;
}
.modal-title {
  color: white;
  font-size: 18pt;
}

.cookie-container {
  padding: 20px;
}

.cookie-titles {
  font-weight: 600;
  font-size: 14pt;
}

.cookie-sub-titles {
  font-weight: 600;
  font-size: 12pt;
}

.separator {
  border-bottom: solid 1pt #CACACA;
}

.btn-container {
  border-radius: 20px!important;;
  width: 30%;
  float: right;
  margin: 0px 20px 20px 20px;
  color: white;
}