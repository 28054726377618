@import "../../../styles/custom.scss";
#root {
  $cursor-type: pointer;
}

.social-post-input-comp {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
  .create-social-post-input {
    background-color: #F0F0F0;
    border-radius: 20px;
    height: 3rem;
    overflow-y: auto;
    cursor: pointer;
    width: 100%;
    border: none;
    padding-left: 3%;
    outline: none;
  }

  .create-social-post-input::placeholder {
    font-family: "Gilroy","sans-serif";
    font-size: .95rem!important;
    color:#bdc1c9;
  }
  
  .create-social-post-input::-webkit-scrollbar {
    width: 0px;
  }

  .input-area {
    height: 80px;
    margin-left: 60px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: calc(100% - 50px);
    padding-left: 10px;
    outline: none;
  }
}
.media-icon {
  color: white;
  font-style: normal;
  font-size: 15px;
}
.media-icons {
  font-size: 15px;
  top: calc(1rem - 2px);
  right: 1.2rem;
}
.post-btn h4 {
  font-weight: 600;
}
.post-btn {
  border-radius: 8px;
  color: white;
  border: none;
  width: 100%;
  font-size: 1rem;
}
.create-social-post-header{
  color: black;
}
.create-social-post-row {
  cursor: pointer;
}
.modal-title {
  width: 100%;
}
.create-social-post-input-modal {
  box-shadow: none;
  border: none;
  background-color: #F0F0F0 !important;
  border-radius: 20px !important;
  height: 6rem !important;
  overflow-y: auto !important;
  margin-bottom: 0px;
}
.create-social-post-input-modal::-webkit-scrollbar {
  width: 0px;
}
.modal-close-btn {
  cursor: pointer;
  text-decoration: none;
  color: black;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}

.create-social-post-modal {
  width: 32rem;
  margin: 0 auto !important;
}

.ql-editor p{
  cursor:  var(--cursor-type) !important;
}
.media-bar {
  margin-top: 5px;
  margin-bottom: 5px;
}

.editor-section {
  width: 100%;
  word-break: break-all;
}

.create-social-post {
  min-height: 100px;
  border-radius: 10px;
}

.ql-toolbar.ql-snow {
  border: none !important;
  padding: 0px !important;
}
.ql-container.ql-snow {
  border: none!important;
}

.text-send {
  vertical-align: sub;
  color: $primary;
}

.files-display-comp-sp {
  .media-container-sp {
    .img-preview-sp {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }
}
.image-container, .doc-container {
  width: 250px;
  height: 200px;
}

.img-preview-modal {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}


.ql-snow .ql-stroke {
  stroke: black;
  stroke-width: 1.5;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter {
  stroke: $primary;
}

.video-display-preview {
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 10px;
  width: 250px;
  height: 200px;
}

.ql-bold, .ql-italic {
  background-color: transparent;
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #C8C8C8;
  font-style: normal;
}

.ql-container {
  font-family: "Gilroy", sans-serif;
  font-size: 0.95rem !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .font-primary-text {
    font-size: 1rem !important;
  }

  .font-secondary-text {
    font-size: 0.8rem !important;
  }

  .ql-editor {
    font-size: 0.8rem !important;
  }
}