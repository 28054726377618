@import 'src/styles/custom.scss';

.profile-image-edit {
  margin-left: auto;
  margin-right: auto;
  display: table;
}
.attribute-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}
.attribute-input {
  height: 39px;
  width: 374px;
  left: 655px;
  top: 436px;
  border-radius: 8px;
  border-color: #676767;
  margin-bottom: 10px;
}
.attribute-textarea {
  height: 140px;
  width: 374px;
  left: 654px;
  top: 788px;
  border-radius: 8px;
  border-color: #676767;
}
.button-container {
  background-color: #C4C4C4;
  border: none;
  height: 38px;
  width: 109px;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  float: right;
  color: black;
  &:hover, &:active{
    color: black;
    background-color: #C4C4C4;
  }

}