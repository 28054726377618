@import 'src/styles/custom.scss';

h1,
h3 {
    font-weight: bold;
}

.mr-25 {
    margin-right: 25px;
}

.w-20 {
    width: 20%;
}

#root [id="page-frame-contents"] .right-side.panel-heading .profile .ml-3,
#root [id="page-frame-contents"] .right-side.panel-heading .profile .mx-3 {
    margin-left: 1rem;
}

.mt-15 {
    margin-top: 15px;
}

.stardiv {
    position: relative;
    min-width: 100px;
}

#root [id="page-frame-contents"] .right-side.panel-heading .profile img {
    margin-left: auto;
    margin-right: auto;
    color: #0091b8;
}

#root [id="page-frame-contents"] .panel-heading .profile .user-awards img {
    margin-left: auto;
    margin-right: auto;
    border: 0px;
    color: #fff;
}

#root [id="page-frame-contents"] .panel-heading .profile .text-engage-primary {
    color: #0091b8;
}

#root [id="page-frame-contents"] .panel-heading .profile .cls-arrow {
    color: #0091b8;
}

.profile {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
}

.profile-image {
    bottom: 0px;
    right: 0px;
}

.btn.btn-default {
    border-color: #9BB9C6;
    color: #045872;
}

.connect-icons-border {
    border-left: solid 2pt;
    border-right: solid 2pt;
    border-color: $secondary;
}

.border-right-enabled {
    border-right: solid 2pt;
    padding-right: 45px;
    border-color: $secondary;
}

.border-left-enabled {
    padding-left: 45px;
}

.connecticon {
    width: 50px;
    height: 50px;
    font-size: 30px;
    color: #294f60;
    float: left;
}

.media-body {
    flex: 1;
    text-align: left;
}

.details .media-body p {
    margin: 0px;
}

.snooze-menu .show>.btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}

#root [id="page-frame-contents"] .panel-heading .profile .thumb32 img {
    border: 0;
}

#root [id="page-frame-contents"] .panel-heading .profile .thumb32 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.user-item {
    max-width: calc(100% - 12px);
    border-bottom: 1px solid white;
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0091b8;
    border-color: #0091b8;
}

.user_post_list {
    height: 390px;
}

#root [id="page-frame-contents"] .col-sm-8 {
    padding-left: 0px;
}

#root [id="page-frame-contents"] .right-side.panel-heading>h1 {
    margin: 0;
    padding: 10px 0;
}

.form-control {
    outline: 0;
}

.details {
    overflow-y: auto;
    overflow-x: hidden;
}

#root [id="page-frame-contents"] .profile .followers-count,
#root [id="page-frame-contents"] .profile .following-count {
    cursor: pointer;
}

#root [id="page-frame-contents"] .profile .followers-count strong {
    margin-right: 3px;
}

#root [id="page-frame-contents"] .profile .following-count strong {
    margin-right: 3px;
}

.listing-scroll {
    height: 70vh;
    padding: 0 5px 20px 20px;
}

.contact-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 190px;
    border-bottom: 1px solid #dee2e6;
}

.image-placement-container {
    margin-top: 80px;
    object-fit: cover;
    border: none !important;
    position: relative;
    width: 150px;
    height: 150px;

    .image-edit-placement {
        height: 130px;
        width: 130px;
        object-fit: cover;
        margin-left: 10px;
        margin-top: 10px;
    }
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #0091b8;
}

.image-placement {
    background-color: #ffffff;
    position: absolute;
    top: 160px;
    left: 28px;
    height: 178px;
    width: 178px;
    object-fit: cover;
    border: 7px solid #ffffff;
}

.image-edit-trigger {
    bottom: -30px;
    width: 100%;
}

.image-edit {
    width: 40px;
    margin: 0 5px 0 52%;
    background-color: white;
    padding: 7px;
    border-radius: 100%;
    border: 1px solid $secondary;
}

.pen-container {
    z-index: 1;
}

.profile-edit {
    float: right;
    margin-right: 30px;
    height: 35px;
    border-radius: 10px;
    text-decoration: none !important;
    width: max-content;
    padding: 0 15px;
}

.header-edit-trigger {
    margin: 0 20px -20px 0;
    z-index: 9;
    background-color: #C2C2C2;
    padding: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-pencil {
    padding: 4px;
    width: 30px !important;
    height: 30px !important;
}

.camera-edit {
    top: 1px;
    position: relative;
    padding: 0 2px 0 2px;
}

.sub-header-text {
    Font: "Gilroy";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    word-break: break-all;
    width: max-content;
    color: #000000;
}

.attribute-title {
    font-weight: 700;
    font-size: 12pt;
}

.buttons-menu {
    margin: 15px 0 15px 0;
}

.social-post-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
}

.chart-button,
.chart-button:focus,
.chart-button:hover {
    border: none;
    background: none;
    box-shadow: none;
}

.org-margin {
    margin-right: 10px;
}

.thumb38 {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    margin: 0 7px 0 7px !important;
    padding: 1px;
}

.connect-link {
    border-bottom: 0px solid #f1f1f1;
}

.fa-star {
    font-style: normal;
}

.mb-100 {
    margin-bottom: 100px;
}

.percentage-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-size: larger;
    font-weight: lighter;
    background-color: #DCDCDC;
    color: black;
    border-radius: 10px;
    min-width: 130px;
    padding: 2px;
    text-align: center;
}

.white-space-pre-line {
    white-space: pre-line;
}

.ms235 {
    margin-left: 235px;
}

.username-section {
    margin-top: 22px;
}

.user-full-name {
    Font: "Gilroy-SemiBold";
    font-size: 24px;
    font-weight: 600 !important;
    letter-spacing: 0em;
    word-break: break-all;
}

.followers-count,
.following-count {
    Font: "Gilroy";
    font-size: 18px;
    font-weight: 400 !important;
    letter-spacing: 0em;
    text-decoration: underline;
    text-underline-offset: 4px;
    width: max-content;
}

.user-fav-icon {
    font-size: 24px;
    margin-right: 5px !important;
    margin-top: -2px;
}

.awards-container {
    margin-top: 10px;
}

.user-following-button,
.user-follow-button,
.user-message-button {
    width: 122px;
    border-radius: 8px !important;
    border: none;
    height: 35px;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

.user-follow-button,
.user-following-button {
    margin-right: 8px;
}

.dropdown-toggle-button {
    padding: 0px !important;
    height: 23px !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.dropdown-toggle-button:focus,
.dropdown-toggle-button:active,
.dropdown-toggle-button:hover,
.dropdown-toggle-button:focus-visible {
    box-shadow: none !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
}

.contact-send-thank-you-button {
    width: 210px;
    height: 36px;
    border-radius: 8px;
}

.contact-remaining-awards {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 100%;
    height: 32px;
    width: 32px;
}

.contact-remaining-awards:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

.contact-awards-count {
    padding-right: 2px;
}

.unfollow-btn {
    text-align: center !important;
}

.following-dropdown-menu {
    inset: 0px -34px auto auto !important;
}

.contact-thank-you-section {
    margin-right: 25px;
    margin-top: 15px;
    justify-content: space-between;

    .send-p2p-btn-text {
        Font: "Gilroy-SemiBold";
        font-size: 16px !important;
        font-weight: 600 !important;
        letter-spacing: 0em;
        padding: 0px;
    }

    .tyc-icon-feed {
        font-size: 21px !important;
    }
}

.contact-details-section {
    height: 75vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.profile-container::-webkit-scrollbar {
    width: 0px !important;
}

.contact-details-section::-webkit-scrollbar {
    width: 7px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.contact-details-section::-webkit-scrollbar-track,
.profile-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px white !important;
}

.contact-details-section::-webkit-scrollbar-thumb,
.profile-container::-webkit-scrollbar-thumb {
    background-color: #8c8c8c !important;
    border-radius: 2px !important;
}

.profile-edit-text {
    Font: "Gilroy-SemiBold";
    font-size: 16px !important;
    font-weight: 600 !important;
}

.user-edit-icon {
    height: 19px;
    width: 19px;
}

.details-tab,
.organization-text,
.posts-tab {
    Font: "Gilroy-SemiBold";
    font-size: 18px;
    font-weight: 600;
}

.details-tab-icon,
.organization-tab-icon,
.posts-tab-icon {
    height: 22px;
    width: 22px;
}

.text-black {
    color: #000000 !important;
}

.contact-details-tab,
.contact-posts-tab,
.contact-organization-tab {
    width: max-content;
    height: 40px;
}

.show-details,
.show-posts,
.chart-button {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0px;
}

@media (max-width: 1200px) {
    .username-section {
        margin-top: 15px !important;
    }

    .image-placement {
        width: 140px !important;
        height: 140px !important;
    }

    .ms235 {
        margin-left: 180px !important;
    }

    .user-following-button {
        width: 110px !important;
        font-size: 14px !important;
    }

    .user-follow-button,
    .user-message-button {
        width: 90px !important;
        font-size: 14px !important;
    }

    .user-full-name {
        font-size: 19px !important;
    }

    .user-fav-icon {
        font-size: 20px !important;
        margin-top: 0px !important;
    }

    .sub-header-text,
    .followers-count,
    .following-count {
        font-size: 14px !important;
    }

    .send-p2p-btn-text {
        font-size: 14px !important;
    }

    .contact-send-thank-you-button {
        min-width: 140px !important;
    }

    .thumb32,
    .contact-remaining-awards {
        width: 25px !important;
        height: 25px !important;
        min-width: 25px !important;
        min-height: 25px !important;
    }

    .contact-remaining-awards span {
        font-size: 12px !important;
    }

    .edit-pencil {
        width: 25px !important;
        height: 25px !important;
    }

    .profile-edit-text {
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .details-tab,
    .organization-text,
    .posts-tab {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .contact-user-detail-section {
        margin-top: 10px !important;
    }
}

@media (max-width: 767px) {
    .username-section {
        margin-top: 10px !important;
    }

    .image-placement {
        width: 100px !important;
        height: 100px !important;
    }

    .ms235 {
        margin-left: 135px !important;
    }
}

@media (max-width: 700px) {
    .username-section-column {
        flex-direction: column !important;
        align-items: start !important;
    }

    .user-following-button {
        margin-left: 0px !important;
    }

    .contact-user-social-button-section {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .contact-user-info-section {
        flex-direction: column;
    }

    .contact-thank-you-section {
        justify-content: start !important;
        margin-left: 135px !important;
    }
}

.details-card {
    padding: 10px !important;
    display: flex;
    gap: 10px;
}

.details-card-description {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.details-card-description :first-child {
    font-size: 18px;
    font-weight: 600;
}

.details-card-icon {
    font-size: 32px;
    line-height: 0.8;
    margin-right: 10px;
    width: 48px;
    text-align: center;
}

.details-card-description :last-child {
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    a {
        color: #000;
    }
}