@import './styles.scss';

.landscape {
   aspect-ratio: 16 / 9;
}

.absolute-stretch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

button.disabled {
    cursor: not-allowed;
}

.flex-grow {
    flex: 1 0 auto;
}

.b0 {
    border-width: 0 !important;
}

.right0 {
    right: 0;
}

.w {
    &-none {
        width: 0 !important;
    }

    &-60 {
        width: 60% !important;
    }
}

.font-10 {
    font-size: .7rem !important;
}

.ellipsis-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.white-space {
    &-nowrap {
        white-space: nowrap;
    }
}

.text-break {
    word-break: break-all;
}

.fit {
    object-fit: cover;
}

.border {
    &-none {
        border: none;
    }

    &-rounded-none {
        border-radius: 0;
    }

    &-bot {
        // remove bootstrap color to apply custom colors
        border-bottom: 1px solid;
    }

    &-solid {
        border-style: solid;
    }

    &-rounded-big {
        border-radius: 2rem !important;
    }
}

.border-w {
    &-2 {
        border-width: 2px !important;
    }

    &-5 {
        border-width: 5px !important;
    }
}

.list-type {
    &-none {
        list-style-type: none;
    }
}

.stick {
    &-top {
        top: 0 !important;
    }

    &-bottom {
        bottom: 0 !important;
    }

    &-left {
        left: 0 !important;
    }

    &-right {
        right: 0 !important;
    }
}

.direction {
    &-ltr {
        direction: ltr;
    }

    &-rtl {
        direction: rtl;
    }
}

.shadow {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3) !important;

    &-none {
        box-shadow: none !important;
    }
}

.vh-100 {
    height: 100vh;
}

.vw-100 {
    width: 100vw;
}

.place-at-top {
    z-index: 100;
}

.text-hover {
    &:hover {
        font-weight: bold;
    }
}

.underline-hover {
    &:hover {
        text-decoration: underline;
    }
}

.link-hover {
    color: $primary;
    cursor: pointer;

    &:hover {
        color: $link-hover;
        text-decoration: underline;
    }
}

//   9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem (base)
// 18px = 1.125rem
$font-sizes: (
    "9": 0.5625rem,
    "10": 0.625rem,
    "11": 0.6875rem,
    "12": 0.75rem,
    "13": 0.8125rem,
    "14": 0.875rem,
    "15": 0.9375rem,
    "16": 1rem,
    "18": 1.125rem,
    "24": 1.5rem
);

@each $font-size-name,
$font-size-code in $font-sizes {
    .font-#{$font-size-name} {
        font-size: $font-size-code
    }
}

$line-height: (
    "0": 0,
    "1": 1
);

@each $line-height-name,
$line-height-code in $line-height {
    .line-height-#{$line-height-name} {
        line-height: $line-height-code
    }
}

$float: (
    "left": left,
    "right": right
);

@each $float-name,
$float-val in $float {
    .float-#{$float-name} {
        float: $float-val
    }
}

$font-weights: (
    "bold": bold,
    "bolder": bolder,
    "lighter": lighter,
    "600": 600,
    "900": 900
);

@each $font-weight-name,
$font-weight-code in $font-weights {
    .font-weight {
        &-#{$font-weight-name} {
            font-weight: $font-weight-code
        }
    }

    .hover {
        &-#{$font-weight-name}:hover {
            font-weight: $font-weight-code
        }
    }
}

$overflow-values: (
    "auto": auto,
    "hidden": hidden,
    "scroll": scroll
);

@each $name,
$value in $overflow-values {
    .overflow-#{$name} {
        overflow: $value !important;
    }

    .overflow-y-#{$name} {
        overflow-y: $value !important;
    }

    .overflow-x-#{$name} {
        overflow-x: $value !important;
    }
}


$cursor-values: (
    "pointer": pointer,
    "not-allowed": not-allowed
);

@each $name,
$value in $cursor-values {
    .c-#{$name} {
        cursor: $value;
    }
}


$margins: (
    "50": 50%,
    "75": 75%,
    "100": 100%
);

@each $margin-name,
$margin-code in $margins {
    .m-#{$margin-name} {
        margin: $margin-code !important;
    }

    .m-top-#{$margin-name} {
        margin-top: $margin-code !important;
    }
}

$percent-sizes: (
    "0": 0,
    "45": 45%,
    "50": 50%,
    "75": 75%,
    "85": 85%,
    "100": 100%
);

@each $size-name,
$size-code in $percent-sizes {
    .max-h {
        &-#{$size-name} {
            max-height: $size-code;
        }
    }

    .max-w {
        &-#{$size-name} {
            max-width: $size-code;
        }
    }

    .top-#{$size-name} {
        top: $size-code;
    }

    .bottom-#{$size-name} {
        bottom: $size-code;
    }

    .left-#{$size-name} {
        left: $size-code;
    }

    .right-#{$size-name} {
        right: $size-code;
    }
}

$thumb-sizes: (
    "22": 22px,
    "24": 24px,
    "32": 32px,
    "48": 48px,
    "64": 64px,
    "75": 75px,
    "200": 200px
);

@each $size-name,
$size-code in $thumb-sizes {
    .thumb#{$size-name} {
        width: $size-code !important;
        height: $size-code !important;
        min-width: $size-code !important;
        min-height: $size-code !important;
        // object-fit: cover;
    }
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}


.modal-dialog-centered {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-header button {
    border: 0;
    background-color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    float: right;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.text-decoration-none {
    text-decoration: none;
}

.search-field-input {
    outline: none;
}

.search-field-input {
    border: transparent;
    background-color: transparent;
    font-size: 16px;
    font-family: 'Gilroy';
    width: 100%;
}

.search-field {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #EEEEEE;
    height: 45px;
    padding: 0 10px;
}

input[type="checkbox"] {
    vertical-align: middle;
}

.unstyled-btn {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    border: 0px solid transparent;
    border-color: #fff;
}

.popup-btn {
    padding: 10px;
    display: flex;
    align-items: center;
}